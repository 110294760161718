import React from 'react'
import videoBg from '../assets/aerial_view_barcelona.mp4';

export const ContainerAboutUs = () => {
  return (
    <div className='about-us'>
            <video className='background-video' autoPlay loop muted playsInline>
              <source src={videoBg} type="video/mp4" />
                Tu navegador no soporta videos.
            </video>
              <div className='content-aboutus'>
                <div className='content-aboutus-left'>
                    <p>Meet RibrodCapital</p>
                    <h1>Building the future by connecting people.</h1>
                </div>
                <div className='content-aboutus-right'>
                    <div>
                        <div class="line"></div>
                    </div>
                    <div className='text-about-us'>
                        <p>Private investment and advisory group specialised in international M&A.</p>
                    </div>
                </div>
            </div>
    </div>
  )
}
export default ContainerAboutUs;