import React from 'react';
import ContainerAboutUs from './ContainerAboutUs';
import OurInvestmentStrategy from './OurInvestmentStrategy';
import AdvisoryAndConsulting from './AdvisoryAndConsulting';
import Footer from './Footer';
import OurPrinciples from './OurPrinciples';

const ViewAboutUs = () => {
  return (
    document.title ="About Us",
    <div>
        <ContainerAboutUs></ContainerAboutUs>
        <OurPrinciples></OurPrinciples>
        <AdvisoryAndConsulting></AdvisoryAndConsulting>
        <OurInvestmentStrategy></OurInvestmentStrategy>
        <Footer></Footer>
    </div>
  )
}
export default ViewAboutUs;

