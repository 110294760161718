import React from 'react';

const AdvisoryAndConsulting = () => {
    return (
        <section className='section-advisory-consulting'>
          
            <div className='container-advisory-consulting'>
                <h1>Advisory and Consulting</h1>
                <p>To maximize opportunities in today’s complex landscape, leaders need advice and judgment from an experienced partner at the forefront of international markets and industries. Our vast network across Europe, North America, LATAM, and China, allows us to connect our clients with potential targets or partners across 4 different continents. We serve our clients as an essential partner in the execution of their vision by leveraging our global network to deliver profitable advantages.</p>
            </div>
        </section>
    );
};

export default AdvisoryAndConsulting;