import React from 'react'
import ContactSection from '../Components/ContactSection';
import Footer from './Footer';


export const ViewContact = () => {
  return (
      document.title ="Contact",
    <div>
          <ContactSection></ContactSection>
          <Footer></Footer>
    </div>

);
}
export default ViewContact;



