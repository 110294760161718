import React from 'react'
import TeamSection from './TeamSection';
import Footer from './Footer';

export const ViewTeam = () => {
  return (
      document.title ="Team",
    <div>
       <TeamSection></TeamSection>
       <Footer></Footer>
    </div>

);
}
export default ViewTeam;



